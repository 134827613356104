import { AxiosRequestConfig } from 'axios';
import { RESOURCE_API_URL } from './constants';

export const getDocumentFileUrl = (documentId: string, documentUnitId: string): string => {
  return `${RESOURCE_API_URL}/database/${documentUnitId}/document/${documentId}`;
};

export const standardAxiosOptions = (token: string): AxiosRequestConfig => {
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'x-forwarded-authorization': `Bearer ${token}`,
  };

  return {
    timeout: 40000,
    headers,
  };
};

export const scoreAdjustment = (score: number): number => {
  return Math.min(1, Math.max(0, score));
};
