import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  showLoading?: boolean;
}

export const AuthSubmitButton = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showLoading, ...buttonProps } = props;
  return (
    <button
      {...buttonProps}
      type="submit"
      className={`group ${showLoading ? 'animate-pulse' : ''} rounded-full p-[3px] bg-qura-neutral-ghost shadow-auth-submit `}
      title={showLoading ? t('common.loading') : props.title}>
      <p className="text-black rounded-full py-[6px] bg-white group-hover:bg-qura-neutral-ghost">
        {showLoading ? t('common.loading') : props.children}
      </p>
    </button>
  );
};
