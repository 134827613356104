import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ResultChunk } from 'src/types/api';
import Layout from '../../components/Layout/Layout';
import LoadingGradient from '../../components/LoadingGradient/LoadingGradient';
import { PDFPreview } from '../../components/PDFPreview/PDFPreview';
import PDFReader from '../../components/PDFReader/PDFReader';
import { MAX_CHUNKS_PER_DOCUMENT } from '../../constants';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { loadOldSearch, setParams } from '../../state/actions/searchParams';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useSearchStore } from '../../state/searchStore';
import { catchAppError } from '@/utils/createAppError';
import { alertError } from '@/utils/alertError';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { setViewedDocument } from '@/services/firebase/functions/setViewedDocument';
import { getOldSearch } from '@/services/firebase/functions/getOldSearch';
import { useDocumentProperties } from '@/services/api/actions/getDocumentProperties';
import ErrorMessage from '@/common/ErrorMessage/ErrorMessage';
import { getDocumentFileUrl } from '@/services/api/utils';

type ChunkPreviewProps = {
  onClick: (value: { number: number; id: number; offset: number }) => void;
  chunk: ResultChunk;
};
const ChunkPreview = ({ chunk, onClick }: ChunkPreviewProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const posthog = usePostHog();
  const { searchId = '' } = useParams();

  return (
    <div
      className="flex flex-col items-centerjustify-between cursor-pointer flex-shrink-0 px-1 gap-2"
      onClick={() => {
        posthog.capture(POSTHOG_EVENT.DOCUMENT_SNIPPET_CLICK, {
          chunkId: chunk.chunkId,
          documentId: chunk.documentId,
          searchId,
        });
        onClick({
          number: chunk.pageNumber,
          id: Math.random(),
          offset: chunk.heightOffset,
        });
      }}
      key={chunk.pageNumber + 1}>
      <PDFPreview
        noTextLayer
        showPageNumber
        hasLoaded={hasLoaded}
        onLoad={() => setHasLoaded(true)}
        chunk={chunk}
        className="rounded shadow-qura p-1"
        loadingLogoHeight={60}
        width={150}
      />
    </div>
  );
};

type ChunkPreviewColumnProps = {
  onClick: (value: { number: number; id: number; offset: number }) => void;
  chunks?: ResultChunk[];
  searchIsLoading?: boolean;
};
const ChunkPreviewColumn = ({ onClick, chunks, searchIsLoading }: ChunkPreviewColumnProps) => {
  const { t } = useTranslation();

  const sortedChunks = useMemo(() => {
    if (!chunks) return null;
    return [...chunks].sort((a, b) => a.pageNumber - b.pageNumber);
  }, [chunks]);

  if (!sortedChunks) return;

  return (
    <div className="flex flex-col items-stretch h-full">
      <div className="flex flex-col w-filter flex-shrink overflow-hidden bg-white shadow-qura rounded-md">
        <div className="border-b border-gray-200 text-sm text-center py-5 ">
          <p>{t('searchDocumentPage.title')}</p>
        </div>
        <div className="px-12 pt-10 pb-12 flex flex-col gap-8 overflow-y-auto items-stretch">
          {!searchIsLoading
            ? sortedChunks.map((chunk) => (
                <ChunkPreview chunk={chunk} onClick={onClick} key={chunk.chunkId} />
              ))
            : Array(MAX_CHUNKS_PER_DOCUMENT)
                .fill(undefined)
                .map((_, index) => <LoadingGradient className="w-36 h-36" key={index} />)}
        </div>
      </div>
    </div>
  );
};

const useDocumentFileUrl = (documentId: string, localDocumentUnitId?: string) => {
  const documentPropertiesQuery = useDocumentProperties(documentId);
  const documentUnitId = localDocumentUnitId || documentPropertiesQuery.data?.documentUnitId;

  return {
    documentProperties: documentPropertiesQuery.data,
    documentFileUrl: documentUnitId ? getDocumentFileUrl(documentId, documentUnitId) : null,
    isLoadingFileUrl: documentPropertiesQuery.isLoading,
    fileUrlError: documentPropertiesQuery.error,
  };
};

const SearchDocumentPage = () => {
  const { searchId, documentId = '' } = useParams();
  const posthog = usePostHog();

  const [searchParams] = useSearchParams();
  const doc = useSearchStore((state) =>
    searchId
      ? (state.searches[searchId]?.results?.documents.find(
          (doc) => doc.documentId === documentId,
        ) ?? null)
      : null,
  );

  useEffect(() => {
    if (!doc && searchId) {
      getOldSearch(searchId)
        .then(({ result, submittedParams }) => {
          loadOldSearch(searchId, result, submittedParams);
          setParams(submittedParams);
        })
        .catch((err) => {
          console.error('Error fetching old search:', err);
          alertError(err, { function: 'getOldSearch' }, { searchId });
          console.error(err);
        });
    }

    if (doc && searchId) {
      setViewedDocument(searchId, doc)
        .then(() => {})
        .catch((err) => {
          alertError(err, { function: 'setViewedDocument' }, { searchId, documentId });
        });
    }
  }, [doc, documentId, searchId]);

  const paramsPageNumber = searchParams.get('page');
  const paramPageOffset = searchParams.get('offset');

  const [pageNumber, setPageNumber] = useState({
    number: parseInt(paramsPageNumber || '0'),
    id: Math.random(),
    offset: parseInt(paramPageOffset || '0'),
  });

  const { t } = useTranslation();

  const firstChunkPageNumber = 0;

  useEffect(() => {
    if (!paramsPageNumber) {
      setPageNumber({
        number: firstChunkPageNumber || 0,
        id: Math.random(),
        offset: 0,
      });
    }
  }, [firstChunkPageNumber, paramsPageNumber]);

  const navigate = useNavigate();

  const query = useSearchStore((state) => state.currentParams?.query);

  const onSearch = () => {
    createNewSearch().then(({ searchId }) => {
      sendSearchParams(searchId);
      navigate(`/search/${searchId}`);
    });
  };

  const selectDocument = (documentId: string) => {
    posthog.capture(POSTHOG_EVENT.SEARCH_DOCUMENT_SUFGGESTION_SELECT, { documentId, searchId });
    navigate(`/document/${documentId}`);
  };

  const backToSearchClick = () => {
    if (searchId) {
      posthog.capture(POSTHOG_EVENT.NAVIGATE_BACK_TO_SEARCH_RESULT, {
        from: 'document',
        searchId,
        documentId,
      });
      navigate(`/search/${searchId}`);
    } else {
      posthog.capture(POSTHOG_EVENT.NAVIGATE_BACK_HOME, { from: 'document', documentId, searchId });
      navigate('/');
    }
  };

  const { documentFileUrl, documentProperties, isLoadingFileUrl, fileUrlError } =
    useDocumentFileUrl(documentId, doc?.documentUnitId);

  return (
    <Layout
      query={query}
      onSearch={onSearch}
      showBackButtonLink={searchId ? `/search/${searchId}` : '/'}
      originalSearchId={searchId}
      selectDocument={selectDocument}
      contentClassName="w-[calc(100%-700px)] max-w-[850px] min-w-[75%] lg:min-w-[600px]"
      leftContent={() =>
        doc && <ChunkPreviewColumn onClick={setPageNumber} chunks={doc.outputChunks} />
      }>
      {isLoadingFileUrl && null}
      {fileUrlError && (
        <div className="flex flex-col gap-2 items-start">
          <ErrorMessage error={catchAppError(fileUrlError)} className="text-xs" />
          <button
            className="bg-white border border-qura-neutral-silver text-qura-neutral-jet hover:bg-qura-neutral-ghost text-xs px-2 py-1.5 rounded"
            onClick={backToSearchClick}>
            {t('searchDocumentPage.backToSearch')}
          </button>
        </div>
      )}
      {documentFileUrl && (
        <PDFReader
          fileProps={doc?.fileProps}
          documentUrl={documentFileUrl}
          documentProperties={documentProperties}
          pageNumber={pageNumber}
        />
      )}
    </Layout>
  );
};

export default SearchDocumentPage;
