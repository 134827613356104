const MAX_HUE = 120;
const MIN_HUE = 55;

const HUE_THRESHOLD = 0.2;
const GRAY_THRESHOLD = 0.2;
const MAX_LIGHT = 0.95;
const MIN_LIGHT = 0.9;

export const getScoreHue = (score: number) => {
  return (
    ((Math.max(Math.min(score, 1), HUE_THRESHOLD) - HUE_THRESHOLD) / (1 - HUE_THRESHOLD)) *
      (MAX_HUE - MIN_HUE) +
    MIN_HUE
  );
};

export const getScoreSaturation = () => {
  return 1;
};

export const getScoreLightness = (score: number) => {
  return (
    (1 - Math.max(Math.min(score, GRAY_THRESHOLD), 0) / GRAY_THRESHOLD) * (MAX_LIGHT - MIN_LIGHT) +
    MIN_LIGHT
  );
};
