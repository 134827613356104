import { useSearchStore } from '../searchStore';

/**
 * Given a search id return a map with the count of all the tags present in the results
 * @param searchId
 * @returns
 */
export const useTagCount = (searchId: string) => {
  const documents = useSearchStore((state) => state.searches[searchId]?.results?.documents);
  return documents?.reduce(
    (acc, doc) => {
      doc.tagIds.forEach((tag) => {
        acc[tag] = acc[tag] ? acc[tag] + 1 : 1;
      });
      return acc;
    },
    {} as Record<string, number>,
  );
};
