import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

const _signupUser = httpsCallable(functions, 'signupUser');

export async function signupUser(
  token: string,
  email: string,
  password: string,
  username: string,
): Promise<string> {
  const result = await _signupUser({ token, email, password, username });

  if (!result.data) {
    throw new Error('No data returned from the function.');
  }

  if (typeof result.data !== 'string') {
    throw new Error('The data returned from the function is not a string.');
  }

  return result.data;
}
