import './LoadingDots.css';

export const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
