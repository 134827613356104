import { useParams } from 'react-router-dom';
import { useSearchStore } from '@/features/Search/state/searchStore';
import { useAvailableFilterTags } from '@/services/api/actions/getAvailableFilterTags';
import { developerMode } from '@/services/posthog/featureFlags';

export const DocumentDeveloperView = (data: any) => (
  <>
    {developerMode && (
      <div className="p-2 mt-4 bg-gray-100 rounded">
        <h3 className="text-sm font-semibold mb-2">Developer Mode: Document</h3>
        <pre className="text-xs overflow-auto">{JSON.stringify(data, null, 2)}</pre>
      </div>
    )}
  </>
);

export const SearchProgressDeveloperView = (progress: any) => (
  <>
    {developerMode && (
      <div className="p-2 mt-4 bg-gray-100 rounded overflow-auto max-h-[300px]">
        <h3 className="text-sm font-semibold mb-2">Developer Mode: Search Progress</h3>
        <pre className="text-xs overflow-auto">{JSON.stringify(progress, null, 2)}</pre>
      </div>
    )}
  </>
);

export const GeneralDeveloperView = () => {
  const search = useSearchStore();
  const filters = useAvailableFilterTags(search.currentParams.dataRole);
  const params = useParams();
  const data = {
    params,
    search: search.currentParams,
    error: search.error,
    filters: filters.data,
  };

  return (
    <>
      {developerMode && (
        <div className="p-2 bg-gray-100 rounded max-h-[400px] overflow-auto m-3">
          <h3 className="text-sm font-semibold mb-2">Developer Mode: General</h3>
          <pre className="text-xs">{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </>
  );
};
