import { User } from 'firebase/auth';
import { StoredUser, getStoredUserRequest } from '../functions/getStoredUser';
import { Company, getCompanyRequest } from '../functions/getCompany';

export type UserData = {
  authUser: User;
  storedUser: StoredUser;
  company: Company;
};

export async function getUserData(user: User): Promise<UserData> {
  const storedUser = await getStoredUserRequest(user.uid);
  const company = await getCompanyRequest(storedUser.companyId);

  return {
    authUser: user,
    storedUser,
    company,
  };
}
