import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../firebaseConfig';

export async function resetPassword(code: string, password: string, passwordConfirmation: string) {
  if (password === passwordConfirmation) {
    await verifyPasswordResetCode(auth, code);
    await confirmPasswordReset(auth, code, password);
  } else {
    throw new Error('Passwords do not match');
  }
}
