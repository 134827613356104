import { images } from '@/assets';

export const AuthContainerCard = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex relative flex-col w-[90%] rounded-large max-w-96 bg-white px-12 overflow-clip shadow-auth-container">
      <img
        src={images.loginBlob}
        className="absolute aspect-square min-w-[950px] w-[950px] self-center bottom-0 z-0 filter grayscale translate-y-[620px] rotate-[110deg]"
      />
      {children}
    </div>
  );
};
