/* eslint-disable react-hooks/exhaustive-deps */
import { usePostHog } from 'posthog-js/react';
import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { POSTHOG_EVENT } from './events';

/**
 * emit an event on location change tracking the maximum scroll depth
 */
export const useSearchDepth = () => {
  const posthog = usePostHog();
  const maxPercentage = useRef(0);
  const maxPixels = useRef(0);
  const location = useLocation();
  const { searchId = '' } = useParams();

  useEffect(() => {
    function handleScroll() {
      const lastPercentage = Math.min(
        1,
        (window.innerHeight + window.scrollY) / document.body.offsetHeight,
      );
      const lastPixels = window.innerHeight + window.scrollY;
      if (lastPercentage > maxPercentage.current) {
        maxPercentage.current = lastPercentage;
      }
      if (lastPixels > maxPixels.current) {
        maxPixels.current = lastPixels;
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      posthog.capture(POSTHOG_EVENT.PAGE_CHANGE, {
        maxScrollShare: maxPercentage.current,
        maxScrollPixels: maxPixels.current,
        lastScrollShare: Math.min(
          1,
          (window.innerHeight + window.scrollY) / document.body.offsetHeight,
        ),
        lastScrollPixels: window.innerHeight + window.scrollY,
        scrolled: maxPixels.current > 0,
        searchId,
      });
    };

    handleRouteChange();
  }, [location, posthog]);
};
