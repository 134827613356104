import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { createAppError } from '@/utils/createAppError';

export async function login(email: string, password: string) {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    const message = error.message;

    if (error.code === 'auth/user-not-found') {
      throw createAppError('invalid-credentials', 'User not found');
    }

    if (error.code === 'auth/invalid-credential') {
      throw createAppError('invalid-credentials', 'Invalid password');
    }

    if (error.code === 'auth/invalid-email') {
      throw createAppError('invalid-credentials', 'Invalid email');
    }

    throw message;
  }
}
