import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

Sentry.init({
  environment: import.meta.env.VITE_NODE_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    posthog.sentryIntegration({
      organization: import.meta.env.VITE_SENTRY_ORG,
      projectId: import.meta.env.VITE_SENTRY_PROJECT,
      severityAllowList: ['error', 'info'],
    }),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
