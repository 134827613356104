import { QueryCache, QueryClient } from '@tanstack/react-query';
import { alertError } from '../../utils/alertError';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      alertError(error, {}, { query: query.queryHash });
    },
  }),
});
