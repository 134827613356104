import { useTranslation } from 'react-i18next';

export const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <p className="z-10 text-xs text-medium mt-8 mx-2 text-center">
      {t('authFooterAlt1')}
      <span className={'text-qura-neutral-balanced'}>{t('authFooterAlt2')}</span>
    </p>
  );
};
