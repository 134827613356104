import { useEffect } from 'react';
import i18n from './services/i18next/i18nextConfig';
import { useSearchStore } from './features/Search/state/searchStore';
import { useUserDataStore } from './services/firebase/state/userDataStore';

export const SetUserLanguage = () => {
  const { data } = useUserDataStore();
  const selectedDataRole = useSearchStore((s) => s.currentParams.dataRole);

  useEffect(() => {
    if (!data) return;

    const { language: defaultLanguage, dataRoles } = data.storedUser;

    const selectedDataRoleLanguage = dataRoles.find(
      (role) => role.roleName === selectedDataRole,
    )?.language;

    i18n.changeLanguage(selectedDataRoleLanguage ?? defaultLanguage);
  }, [data, selectedDataRole]);

  return null;
};
