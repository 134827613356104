import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { availableDateRangeSchema } from '../apiValidation';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

async function getAvailableDateRangeRequest(dataRole?: string, tagIds: string[] = []) {
  if (!dataRole) {
    return null;
  }
  const token = await getAuthToken();
  const response = await axios.post(
    `${RESOURCE_API_URL}/database/filters/dates`,
    {
      data_role: dataRole,
      tagIds,
    },
    standardAxiosOptions(token),
  );

  return availableDateRangeSchema.parse({
    after_date: response.data.start_date,
    before_date: response.data.end_date,
  });
}

export const useAvailableDateRange = (dataRole?: string, tagIds: string[] = []) => {
  return useQuery({
    queryKey: ['filter_dates', dataRole, ...tagIds],
    queryFn: () => getAvailableDateRangeRequest(dataRole, tagIds),
  });
};
