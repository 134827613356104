/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';
import { getAuth } from 'firebase/auth';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import { verifyEmailAndSignIn } from '../../hooks/useEmailVerify';
import { alertError } from '@/utils/alertError';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { logout } from '@/services/firebase/functions/logout';
import devlog from '@/utils/devlog';

export const ConfirmEmailEndpoint = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const confirmationCode = searchParams.get('confirmation');

  // On mount, confirm email and then close the window
  useEffect(() => {
    if (confirmationCode) {
      setIsLoading(true);
      reloadIdToken().then(() => {
        if (getAuth().currentUser?.emailVerified) {
          navigate('/auth');
        } else {
          devlog('confirmationCode', confirmationCode);
          verifyEmailAndSignIn(confirmationCode)
            .then(() => {
              reloadIdToken();
              posthog.capture(POSTHOG_EVENT.NEW_EMAIL_VERIFICATION);

              navigate('/auth');
            })
            .catch((error) => {
              alertError(error, { function: 'verifyEmailAndSignIn' }, { confirmationCode });
              setError(true);
              setIsLoading(false);
            });
        }
      });
    }
  }, [confirmationCode]);

  // Navigate to login page
  const loginClick = () => {
    logout().then(() => {
      navigate('/auth');
    });
  };

  // Reload the id token
  const reloadIdToken = async () => {
    const authUser = getAuth().currentUser;
    if (authUser) {
      await authUser.reload();
      devlog('Reloaded id token', authUser.emailVerified);
    }
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthInputCard>
          <div className="h-12" />
          <AuthLogo />
          <AuthDescription text={t('confirmEmailPage.confirming')} />
          {isLoading && !error && (
            <div className="flex justify-center items-center h-10">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          )}
          {error && (
            <div className="h-10">
              <p className="text-center text-sm text-red-500">
                {t('confirmEmailPage.confirmingError')}
              </p>
            </div>
          )}
          <div className="h-20" />
          <AuthSubmitButton onClick={loginClick}>
            {t('confirmEmailPage.backToLogin')}
          </AuthSubmitButton>
          <div className="h-12" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
    </AuthBackground>
  );
};
