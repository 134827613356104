import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { createAppError } from '@/utils/createAppError';

export async function forgotPassword(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    throw createAppError(
      'unknown',
      'Failed to send forgot password email with Firebase. Firebase Error Message: ' +
        error.message,
    );
  }
}
