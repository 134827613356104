import posthog from 'posthog-js';
import { useSearchStore } from '../searchStore';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { alertError } from '@/utils/alertError';

export const sendSearchParams = (searchId: string) => {
  const state = useSearchStore.getState();

  if (state.currentParams.query.trim().length === 0) {
    return;
  }

  const ws = state.searches[searchId]?.ws;
  if (!ws) {
    alertError(new Error('No websocket connection'), { function: 'sendSearchParams' });
    return;
  }

  const currentParams = useSearchStore.getState().currentParams;
  const params = {
    ...currentParams,
  };

  const payload = convertBackToSnakeCaseRecursive({
    content_type: 'search',
    content: params,
  });

  posthog.capture(POSTHOG_EVENT.SEARCH_START, {
    params: params,
    searchId,
  });

  ws.send(JSON.stringify(payload));
};
