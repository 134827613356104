import { useTranslation } from 'react-i18next';
import { useFilterBeenUsed } from '../../state/hooks/useFilterBeenUsed';

type FilterHeaderProps = {
  onResetClick: () => void;
};
export const FilterHeader = ({ onResetClick }: FilterHeaderProps) => {
  const beenUsed = useFilterBeenUsed();
  const { t } = useTranslation();

  return (
    <div className="flex justify-between px-5">
      <p className="font-semibold text-qura-neutral-jet text-sm  mb-2 tracking-wide">
        {t('filters.filter')}
      </p>
      {beenUsed && (
        <button
          className="hover:underline text-qura-neutral-jet text-xs mr-2 mb-2 tracking-wide"
          onClick={onResetClick}>
          {t('filters.reset')}
        </button>
      )}
    </div>
  );
};
