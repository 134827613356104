/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import { MatchScore } from '../MatchScore/MatchScore';
import { SearchProgressDeveloperView } from '@/utils/developerMode';
import { SearchProgress } from '@/types/api';
import { Icons } from '@/assets';

const DebounceValue = ({
  targetValue,
  increment,
  time,
}: {
  targetValue: number;
  increment: number;
  time: number;
}) => {
  const [currentValue, setCurrentValue] = useState(targetValue);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (targetValue !== currentValue) {
      interval = setInterval(() => {
        setCurrentValue((currentValue) => Math.min(currentValue + increment, targetValue));
        if (currentValue >= targetValue) {
          clearInterval(interval);
          interval = undefined;
        }
      }, time);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [targetValue]);

  return (
    <p className="text-xs text-center py-0.5 min-w-9 rounded text-qura-neutral-jet bg-qura-neutral-ghost px-2">
      {new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: 0,
      })
        .format(Math.ceil(currentValue))
        .replace(/,/g, ' ')}
    </p>
  );
};

type SearchStatusProps = {
  foundDocuments: number;
  progress: SearchProgress | null;
};

const SearchStatus: FC<SearchStatusProps> = ({ foundDocuments, progress }) => {
  const { t } = useTranslation();
  const sortedStages = Object.entries(progress?.stages || {}).sort(
    (a, b) => a[1].weight - b[1].weight,
  );

  return (
    <div className="p-6 h-fit pointer-events-auto flex flex-col rounded-md shadow-qura bg-white text-xs w-filter relative group">
      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">{t('searchStatus.searchScore')}</p>
        <MatchScore
          score={progress?.searchScore === 0 ? null : (progress?.searchScore ?? null)}
          className="text-xs text-center w-9 py-0.5 rounded pl-0.5"
        />
      </div>
      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">{t('searchStatus.documentsFound')}</p>
        <p className="text-xs text-center w-9 py-0.5 bg-qura-neutral-ghost rounded text-qura-neutral-jet">
          {foundDocuments}
        </p>
      </div>

      <div className="flex items-center justify-between mb-4">
        <p className="text-xs mt-0.5 text-qura-neutral-jet">
          {t('searchStatus.documentsSearched')}
        </p>
        <DebounceValue
          targetValue={progress?.searchedDocuments ?? 0}
          increment={
            progress?.complete
              ? (progress?.searchedDocuments ?? 0)
              : (progress?.searchedDocuments ?? 0) / 50
          }
          time={progress?.searchedDocuments ? 50 : 0}
        />
      </div>

      <div className="h-4" />
      <div className="flex flex-col gap-3">
        {sortedStages.map(([name, stage]) => {
          return (
            <ChainLink key={name} title={t(`searchStatus.${name}`)} chainStatus={stage.status} />
          );
        })}
      </div>
      <SearchProgressDeveloperView data={progress} />
    </div>
  );
};

type ChainLinkProps = {
  title: string;
  chainStatus: 'pending' | 'completed' | 'ongoing' | 'error';
};

const ChainLink: FC<ChainLinkProps> = ({ chainStatus, title }) => {
  const { t } = useTranslation();

  const getOuterCircleStyles = () => {
    const baseStyles = 'flex items-center justify-center rounded-full w-7 h-7 border-2 ';
    const statusStyles = {
      ongoing: 'bg-qura-neutral-ghost border-qura-neutral-ghost',
      completed: 'bg-qura-neutral-jet border-qura-neutral-jet',
      pending: 'bg-qura-neutral-ghost border-qura-neutral-ghost',
      error: 'border-red-500',
    };
    return `${baseStyles} ${statusStyles[chainStatus]}`;
  };

  const getInnerCircleStyles = () => {
    const baseStyles = 'flex items-center justify-center rounded-full';
    const statusStyles = {
      ongoing: 'w-5 h-5 bg-qura-neutral-ghost inset-0',
      completed: 'w-5 h-5 bg-qura-neutral-jet',
      pending: 'w-5 h-5 bg-qura-neutral-ghost',
      error: 'w-5 h-5 bg-red-500',
    };
    return `${baseStyles} ${statusStyles[chainStatus]}`;
  };

  const renderStatus = () => {
    const statusTranslations = {
      ongoing: t('searchStatus.ongoing'),
      completed: t('searchStatus.completed'),
      pending: t('searchStatus.pending'),
      error: t('searchStatus.error'),
    };
    return statusTranslations[chainStatus];
  };

  return (
    <div className="flex flex-col items-start justify-start mb-2">
      <div className="flex items-center h-10 gap-3">
        <div className={getOuterCircleStyles()}>
          <div className={getInnerCircleStyles()}>
            {chainStatus !== 'completed' && chainStatus !== 'error' && <LoadingDots />}
            {chainStatus === 'completed' && <Icons.Checked className="text-white" />}
          </div>
        </div>
        <div className="">
          <p className="text-sm">{title}</p>
          <p className="text-xs text-qura-neutral-balanced">{renderStatus()}</p>
        </div>
      </div>
    </div>
  );
};

export default SearchStatus;
