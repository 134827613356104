import { ReactNode, useEffect, useRef, useState } from 'react';

type SideColumn = {
  children: ReactNode;
  side: 'left' | 'right';
};
export const SideColumn = ({ children, side }: SideColumn) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = useState(0);
  const [placeholderWidth, setPlaceholderWidth] = useState(0);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      if (contentRef.current) setContentWidth(contentRef.current.clientWidth);
      if (placeholderRef.current) setPlaceholderWidth(placeholderRef.current.clientWidth);
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    window.addEventListener('resize', updateDimensions);

    if (contentRef.current) resizeObserver.observe(contentRef.current);
    if (placeholderRef.current) resizeObserver.observe(placeholderRef.current);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, [contentRef, placeholderRef]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const placeholderStyles = {
    left: 'justify-end',
    right: 'justify-start',
  };

  const contentStyles = {
    left: 'right-0',
    right: 'left-0',
  };

  const translateDirection = side == 'right' ? -1 : 1;

  return (
    <div ref={placeholderRef} className={`relative w-full h-full ${placeholderStyles[side]} `}>
      <div
        ref={contentRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`absolute ${contentStyles[side]} max-h-full h-full top-0 transition-transform px-4`}
        style={{
          transform:
            isHover && contentWidth > placeholderWidth
              ? `translateX(${(contentWidth - placeholderWidth) * translateDirection}px)`
              : 'none',
        }}>
        {children}
      </div>
    </div>
  );
};
