export const AuthDescription = ({ text }: { text: string }) => {
  return (
    <div className="flex relative flex-col items-center text-sm font-light mb-8">
      {text.split('\n').map((line, index) => (
        <p key={index} className="text-center">
          {line}
        </p>
      ))}
    </div>
  );
};
