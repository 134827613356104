import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { databaseInfoResponseSchema } from '@/services/api/apiValidation.ts';

async function getDatabaseInfo() {
  const token = await getAuthToken();
  const response = await axios.get(
    `${RESOURCE_API_URL}/database/dbinfo`,
    standardAxiosOptions(token),
  );
  const validated = databaseInfoResponseSchema.parse(response.data);
  return validated;
}

export const useDatabaseInfo = () => {
  return useQuery({
    queryKey: ['database_info'],
    queryFn: getDatabaseInfo,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
