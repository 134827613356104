import { useEffect, useRef, useState } from 'react';

import isEqual from 'lodash.isequal';
import { PDFReaderTopBar } from './PDFReaderTopBar';
import Virtualizer, { PageNumber } from './Virtualizer';
import { DocumentProperties } from '@/types/api';
import { Icons } from '@/assets';
import { FileProps } from '@/services/api/apiValidation';

interface PDFReaderProps {
  documentUrl: string;
  documentProperties?: DocumentProperties;
  pageNumber: PageNumber;
  legalId?: string;
  fileProps?: FileProps;
}

const PDFReader = (props: PDFReaderProps) => {
  const { documentUrl, pageNumber, documentProperties, fileProps } = props;
  const [scale, setScale] = useState(100);
  const [currentPage, setCurrentPage] = useState(pageNumber.number);
  const [scrollToPage, setScrollToPage] = useState(pageNumber);
  const [virtualizerLoaded, setVirtualizerLoaded] = useState(false);
  const [virtualizerKey, setVirtualizerKey] = useState(0);
  const filePropsRef = useRef(fileProps);

  useEffect(() => {
    setScrollToPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (isEqual(fileProps, filePropsRef.current)) return;
    filePropsRef.current = fileProps;
    setVirtualizerKey((prevKey) => prevKey + 1);
  }, [fileProps]);

  return (
    <>
      <div className="grid grid-cols-1 grid-rows-[3.5rem_1fr] relative rounded-xl shadow-qura h-full w-full bg-white overflow-clip">
        <PDFReaderTopBar
          scale={scale}
          setScale={setScale}
          currentPage={currentPage}
          setScrollToPage={setScrollToPage}
          documentProperties={documentProperties}
          documentUrl={documentUrl}
          fileProps={fileProps}
        />
        {documentProperties && (
          <Virtualizer
            key={virtualizerKey}
            pageNumber={scrollToPage}
            fileUrl={documentUrl}
            pageCount={documentProperties.pageCount}
            pageMetadata={documentProperties.pageProperties}
            setCurrentPage={setCurrentPage}
            scale={(scale / 100) * documentProperties.scaleFactor}
            onLoadSuccess={() => setVirtualizerLoaded(true)}
            fileProps={filePropsRef.current}
          />
        )}
        {(!virtualizerLoaded || !documentProperties) && (
          <div className="absolute bg-white top-[3.5rem] bottom-0 left-0 right-0 flex items-center justify-center">
            <Icons.LogoText className="h-6 animate-pulse" />
          </div>
        )}
      </div>
    </>
  );
};

export default PDFReader;
