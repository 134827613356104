import { useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { alertError } from '../../../utils/alertError';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { FileProps } from '../apiValidation';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';

export const convertFilePropsToSnakeCase = (fileProps: FileProps): Record<string, any> => {
  return {
    file_props: convertBackToSnakeCaseRecursive(fileProps),
  };
};

export const getFile = async (
  documentId: string,
  documentUnitId: string,
  fileProps?: FileProps,
): Promise<Blob> => {
  try {
    const token = await getAuthToken();
    const url = `${RESOURCE_API_URL}/database/${documentUnitId}/document/${documentId}`;
    const options: AxiosRequestConfig = {
      ...standardAxiosOptions(token),
      responseType: 'blob',
      timeout: 20_000,
    };
    let response;
    if (fileProps) {
      response = await axios.post(url, convertFilePropsToSnakeCase(fileProps), options);
    } else {
      response = await axios.get(url, options);
    }

    if (!(response.data instanceof Blob)) {
      throw new Error('Excerpt is not a Blob');
    }
    return response.data;
  } catch (error) {
    alertError(error, { function: 'getFile' }, { documentId, fileProps });
    throw error;
  }
};

export const useFile = (
  documentId: string,
  documentUnitId: string,
  fileProps: FileProps,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['file', documentId, fileProps],
    queryFn: async () => getFile(documentId, documentUnitId, fileProps),
    throwOnError: false,
    retryDelay: 2000,
    enabled,
  });
};
