import LoadingGradient from '../../components/LoadingGradient/LoadingGradient';

const CARD_HEIGHT = 237;

type Props = {
  willBeRemoved?: boolean;
};
export const LoadingResultCard = ({ willBeRemoved }: Props) => {
  return (
    <div
      className="flex flex-col relative self-stretch bg-white shadow-qura rounded-lg px-4 pt-4 pb-3 animate-fade-in"
      style={{ height: CARD_HEIGHT }}>
      <LoadingGradient className="bg-qura-neutral-silver rounded-sm w-[50%] h-5 opacity-40" />
      <div className="h-2.5" />
      <LoadingGradient className="bg-qura-neutral-silver w-[95%] rounded-sm  h-5 opacity-40" />
      <div className="h-2.5" />
      <LoadingGradient className="bg-qura-neutral-silver rounded-sm flex-1 opacity-50" />
      {willBeRemoved && (
        <div
          className={`animate-fade-in absolute top-0 left-0 w-full h-full bg-white z-10 rounded-lg pointer-events-none`}
        />
      )}
    </div>
  );
};
