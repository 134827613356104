import { FC, useState } from 'react';
import AuthTextInput, { IAuthTextInput } from './AuthTextInput';
import { Icons } from '@/assets';

interface IAuthPasswordInput
  extends Omit<IAuthTextInput, 'Icon' | 'RightIcon' | 'onRightIconClick' | 'type'> {}

const AuthPasswordInput: FC<IAuthPasswordInput> = (props) => {
  const { isError, placeholder, id, label, value, onChange, ...inputProps } = props;

  const [isPasswordHide, setPasswordHide] = useState(true);

  const handleRightIconClick = () => {
    setPasswordHide(!isPasswordHide);
  };

  return (
    <AuthTextInput
      label={label}
      onChange={onChange}
      type={isPasswordHide ? 'password' : 'text'}
      id={id}
      value={value}
      placeholder={placeholder}
      Icon={Icons.Lock}
      RightIcon={isPasswordHide ? Icons.ClosedEye : Icons.OpenedEye}
      isError={isError}
      onRightIconClick={handleRightIconClick}
      {...inputProps}
    />
  );
};

export default AuthPasswordInput;
