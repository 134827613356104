import { useQuery } from '@tanstack/react-query';
import { auth } from '../firebaseConfig';

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const getAuthToken = async () => {
  const token = await auth.currentUser?.getIdToken();
  return token || '';
};

export const useAuthToken = () => {
  return useQuery({
    queryKey: ['authToken'],
    queryFn: getAuthToken,
  });
};
