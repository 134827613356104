import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { LoadingCover } from '../components/LoadingCover';

type AuthRedirectProps = {
  onLoggedIn?: string;
  onLoggedOut?: string;
  onEmailNotVerified?: string;
  children: React.ReactNode;
};
export const AuthRedirect = ({
  children,
  onLoggedIn,
  onLoggedOut,
  onEmailNotVerified,
}: AuthRedirectProps) => {
  const location = useLocation();
  const auth = getAuth();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (!loading) {
    if (user && !user.emailVerified && onEmailNotVerified) {
      return <Navigate to={onEmailNotVerified} replace state={{ from: location }} />;
    }

    if (user && onLoggedIn) {
      return <Navigate to={onLoggedIn} replace state={{ from: location }} />;
    }

    if (!user && onLoggedOut) {
      return <Navigate to={onLoggedOut} replace state={{ from: location }} />;
    }
  }

  return <LoadingCover isLoading={loading}>{children}</LoadingCover>;
};
