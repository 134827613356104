import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { availableFiltersSchema } from '../apiValidation';

async function getAvailableFilterTagsRequest(dataRole: string) {
  const docRef = doc(db, 'dataRoles', dataRole);
  const document = await getDoc(docRef);
  if (!document.exists()) {
    return null;
  }

  const data = document.data();
  return availableFiltersSchema.parse(data.tag_tree);
}

export const useAvailableFilterTags = (dataRole: string | null) => {
  return useQuery({
    queryKey: ['filter_tags', dataRole],
    queryFn: () => getAvailableFilterTagsRequest(dataRole ?? ''),
    enabled: dataRole !== null,
  });
};
