const LoadingGradient = ({
  className,
  width,
  height,
}: {
  className?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <div
      className={'loading-horizontal border-none ' + className}
      style={{
        width: width,
        height: height,
      }}
    />
  );
};

export default LoadingGradient;
