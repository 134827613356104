import React, { FC } from 'react';
import { Icons } from 'src/assets';

export interface IAuthTextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon: typeof Icons.LogoText;
  RightIcon?: typeof Icons.LogoText;
  iconClassName?: string;
  label?: string;
  isError?: boolean;
  onRightIconClick?: () => void;
}

const AuthTextInput: FC<IAuthTextInput> = (props) => {
  const { label, isError, Icon, iconClassName, RightIcon, onRightIconClick, ...inputProps } = props;
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <>
      <label className={`text-xs font-medium mb-1 ${isError ? 'text-qura-red' : ''}`}>
        {label}
      </label>
      <div
        className={`bg-qura-neutral-ghost flex items-center px-2 py-[1px] rounded-md ${isError ? 'border-qura-red border-2' : 'border-transparent'}`}>
        <Icon className={`text-black w-4 ${isFocused ? '' : ''} ${iconClassName}`} />
        <input
          {...inputProps}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`focus:outline-none focus:border-none font-inter text-xs bg-transparent rounded-smaller w-full py-2 px-2 text-black leading-tight outline-0 placeholder-qura-neutral-jet/50`}
          style={{
            transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
          }}
        />
        {RightIcon && (
          <RightIcon
            onClick={onRightIconClick}
            className={`cursor-pointer text-black w-4 ${isFocused ? '' : ''} ${iconClassName}`}
          />
        )}
      </div>
    </>
  );
};

export default AuthTextInput;
