import { PropsWithChildren } from 'react';

interface Props {
  onSubmit?: () => void;
  className?: string;
}

export const AuthInputCard = ({ children, onSubmit, className }: PropsWithChildren<Props>) => {
  return (
    <div
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (onSubmit) {
            onSubmit();
          }
        }
      }}
      className={`flex relative flex-col items-stretch ${className ?? ''}`}>
      {children}
    </div>
  );
};
