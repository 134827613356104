import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

const _sendConfirmationEmail = httpsCallable(functions, 'sendConfirmationEmail');

export async function sendConfirmationEmail(email: string): Promise<boolean> {
  try {
    const domain = window.location.origin;
    await _sendConfirmationEmail({ email, domain });
    return true;
  } catch (error: any) {
    throw new Error(`An error occurred while sending the confirmation email. ${error.message}`);
  }
}
