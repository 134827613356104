import { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import Tag from '../Tag/Tag';
import { Icons } from '@/assets';
import { ResultChunk } from '@/types/api';
import { getScoreHue } from '@/utils/getScoreHue';
import { alertError } from '@/utils/alertError';
import { useFile } from '@/services/api/actions/getFile';

type Props = {
  chunk: ResultChunk;
  inactive?: boolean;
  showPageNumber?: boolean;
  className?: string;
  noTextLayer?: boolean;
  width?: number;
  scale?: number;
  hasLoaded: boolean;
  onLoad: () => void;
  loadingLogoHeight?: number;
  adjustColorToScore?: number;
};

const isSafari = (): boolean => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const IS_SAFARI = isSafari();

export const PDFPreview: FC<Props> = ({
  className,
  chunk,
  showPageNumber,
  noTextLayer,
  width,
  scale,
  loadingLogoHeight,
  hasLoaded,
  onLoad,
  adjustColorToScore,
  inactive,
}) => {
  const { t } = useTranslation();
  const { data: pdfExcerptBlob } = useFile(
    chunk.documentId,
    chunk.documentUnitId,
    chunk.excerpt,
    !inactive,
  );
  const [pdfBase64, setPdfBase64] = useState<string | null>(null);
  const highlightHue = 110;
  const rotateBy = adjustColorToScore ? getScoreHue(adjustColorToScore) - highlightHue : null;

  const onError = (error: unknown) => {
    alertError(error, { component: 'PDFPreview' }, { chunk });
  };

  useEffect(() => {
    if (!pdfExcerptBlob) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64data = reader.result;
      if (typeof base64data === 'string') {
        setPdfBase64(base64data);
      }
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    reader.readAsDataURL(pdfExcerptBlob);
  }, [pdfExcerptBlob]);

  const onLoadSuccess = () => {
    if (IS_SAFARI) {
      setTimeout(() => {
        onLoad();
      }, 1000);
    } else {
      onLoad();
    }
  };

  return (
    <div
      className={`flex relative flex-1 w-full justify-center overflow-clip ${className}`}
      style={{
        height: hasLoaded ? undefined : loadingLogoHeight,
        filter: rotateBy ? `hue-rotate(${Math.floor(rotateBy)}deg)` : undefined,
      }}>
      {!inactive && (
        <Document
          loading={<p>Loading...</p>}
          noData={<p>No data</p>}
          error={t('searchResult.previewError')}
          onError={onError}
          onLoadError={onError}
          onSourceError={onError}
          onLoadSuccess={onLoadSuccess}
          file={pdfBase64}>
          <Page
            loading={<></>}
            pageNumber={1}
            width={width}
            scale={scale}
            renderAnnotationLayer={false}
            renderTextLayer={!noTextLayer}
          />
        </Document>
      )}
      {loadingLogoHeight && (
        <div
          className={`flex absolute bg-white w-full h-full pointer-events-none ${hasLoaded ? 'animate-fade-out-fast' : ''}`}>
          <div
            className="w-full flex items-center justify-center"
            style={{ height: loadingLogoHeight }}>
            <Icons.LogoText className={`h-4 animate-pulse`} />
          </div>
        </div>
      )}
      {showPageNumber && (
        <Tag className={`absolute right-1 bottom-1 'bg-green-100 text-green-700'}`}>
          {t('searchResult.pageNumberShort')} {chunk.pageNumber + 1}
        </Tag>
      )}
    </div>
  );
};
