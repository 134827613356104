import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { documentPropertiesSchema } from '../apiValidation';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

async function getDocumentProperties(documentId: string) {
  const token = await getAuthToken();
  const response = await axios.get(
    `${RESOURCE_API_URL}/database/document/${documentId}/properties`,
    standardAxiosOptions(token),
  );
  return documentPropertiesSchema.parse(response.data);
}

export const useDocumentProperties = (documentId: string) => {
  return useQuery({
    queryKey: ['filter_dates', documentId],
    queryFn: () => getDocumentProperties(documentId),
    retry: 1,
  });
};
