import { usePostHog } from 'posthog-js/react';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FilterBadge } from './FilterBadge';
import { FilterHeader } from './FilterHeader';
import TagButton from './TagButton';
import { YearFilter } from './YearFilter';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { resetFilter } from '../../state/actions/searchParams';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useFilterHasChanged } from '../../state/hooks/useFilterHasChanged';
import { useSearchStore } from '../../state/searchStore';
import { AvailableFilters, TagNode } from '@/types/api';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { GeneralDeveloperView } from '@/utils/developerMode';
import { alertError } from '@/utils/alertError';

const renderTag = (option: TagNode, parentTag: TagNode | null) => {
  return (
    <TagButton key={option.tagId} tag={option} parentTag={parentTag} renderSubTag={renderTag} />
  );
};

const FilterDivider = () => {
  return <div className={'h-[1px] flex-shrink-0 bg-qura-neutral-silver mx-5 '} />;
};

const FilterTags = ({ tagsFilter }: { tagsFilter: AvailableFilters }) => {
  const sortedTagNodes = useMemo(
    () => tagsFilter.tagNodes.sort((a, b) => a.weight - b.weight),
    [tagsFilter],
  );

  return (
    <div className="flex flex-col flex-shrink overflow-y-auto items-stretch w-full px-5 gap-1.5 py-2">
      {sortedTagNodes.map((tag) => renderTag(tag, null))}
    </div>
  );
};

interface ApplyButtonProps {
  searchId: string;
  isCreatingSearch: boolean;
  onApplyFilters: () => void;
}

const ApplyButton = (props: ApplyButtonProps) => {
  const { onApplyFilters, searchId, isCreatingSearch } = props;
  const hasChanged = useFilterHasChanged(searchId);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col mx-5 text-xs">
      <button
        disabled={!hasChanged}
        className={` border ${isCreatingSearch ? 'animate-pulse' : ''} border-qura-neutral-silver rounded-md mt-0.5 py-1.5 text-center ${
          !hasChanged
            ? ' bg-qura-neutral-ghost text-qura-neutral-light cursor-not-allowed'
            : 'bg-qura-neutral-jet text-white hover:bg-qura-neutral-balanced font-medium'
        }`}
        onClick={onApplyFilters}>
        {t('filters.applyFilters')}
      </button>
    </div>
  );
};

export const Filtering: FC<{
  className?: string;
  searchId: string;
  tagsFilter: AvailableFilters;
}> = ({ searchId, tagsFilter }) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [isCreatingSearch, setIsCreatingSearch] = useState(false);

  const makeNewSearch = async () => {
    setIsCreatingSearch(true);
    createNewSearch()
      .then(({ searchId }) => {
        const filter = useSearchStore.getState().currentParams.filter;
        posthog.capture(POSTHOG_EVENT.FILTER_APPLY, { searchId, filter });
        sendSearchParams(searchId);
        navigate(`/search/${searchId}`);
        setIsCreatingSearch(false);
      })
      .catch((error) => {
        alertError(error);
        setIsCreatingSearch(false);
      });
  };

  const onResetClick = () => {
    const filter = useSearchStore.getState().currentParams.filter;
    posthog.capture(POSTHOG_EVENT.FILTER_RESET, { searchId, filter });
    resetFilter();
    makeNewSearch();
  };

  return (
    <>
      <div className="w-filter flex flex-col flex-shrink rounded-md shadow-qura bg-white py-3">
        <FilterHeader onResetClick={onResetClick} />
        <FilterDivider />
        <FilterTags tagsFilter={tagsFilter} />
        <FilterDivider />
        <div className="h-2" />
        <YearFilter />
        <div className="h-2" />
        <FilterDivider />
        <div className="h-2" />
        <ApplyButton
          isCreatingSearch={isCreatingSearch}
          searchId={searchId}
          onApplyFilters={makeNewSearch}
        />
        <GeneralDeveloperView />
      </div>
      <FilterBadge searchId={searchId} />
    </>
  );
};
