import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { PropsWithChildren, useEffect } from 'react';
import { getUserData } from './getUserData';
import { useUserDataStore } from './userDataStore';
import { alertError } from '../../../utils/alertError';
import { logout } from '../functions/logout';

const MIN_LOADING_TIME_MS = 340;

export const UserDataProvider = ({ children }: PropsWithChildren) => {
  const auth = getAuth();

  const authStateChanged = async (user: User | null) => {
    if (!user) {
      useUserDataStore.setState({ data: null, isInitialized: true }, false, 'user/no-user');
      return;
    }

    const waitPromise = new Promise((resolve) => setTimeout(resolve, MIN_LOADING_TIME_MS));
    Promise.all([getUserData(user), waitPromise])
      .then(([userData]) => {
        useUserDataStore.setState(
          { data: userData, isInitialized: true },
          false,
          'user/initialized',
        );
      })
      .catch((error) => {
        useUserDataStore.setState({ data: null, isInitialized: true }, false, 'user/error');
        alertError(error, { function: 'getUserData' }, { user: user.toJSON() });
        logout();
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);

    return () => unsubscribe();
  }, [auth]);

  return children;
};
