import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getLogMetadata } from '@/utils/getLogData';
import { getAuth } from 'firebase/auth';
import posthog from 'posthog-js';
import { POSTHOG_EVENT } from '@/services/posthog/events';

export async function sendFeedback(message: string): Promise<void> {
  const authUser = getAuth().currentUser;
  const userId = authUser?.uid ?? null;
  posthog.capture(POSTHOG_EVENT.FEEDBACK_SENT, { userId, feedback: message });
  await addDoc(collection(db, 'feedback'), {
    message: message,
    data: getLogMetadata(),
    link: window.location.href,
    platform: window.location.origin.replace('https://', ''),
    timestamp: new Date(),
    userId,
  });
}
