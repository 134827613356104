import { useEffect } from 'react';

const useAppropriateFavicon = () => {
  useEffect(() => {
    // Function to update favicon
    const updateFavicon = (mode: 'dark' | 'light') => {
      const link: HTMLLinkElement =
        document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.href = link.href.replace(/favicon-(light)|(dark)/i, `favicon-${mode}`);
      document.getElementsByTagName('head')[0]?.appendChild(link);
    };

    // Function to toggle favicon based on the system theme
    const toggleFavicon = (e: MediaQueryListEvent) => {
      const isDarkMode = e.matches; // Check if the dark mode is enabled
      updateFavicon(isDarkMode ? 'dark' : 'light');
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', toggleFavicon);

    toggleFavicon({ matches: mediaQuery.matches } as MediaQueryListEvent);

    return () => mediaQuery.removeEventListener('change', toggleFavicon);
  }, []);
};

export default useAppropriateFavicon;
