import { useEffect } from 'react';
import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useUserDataStore } from './services/firebase/state/userDataStore';
import { db } from './services/firebase/firebaseConfig';
import { alertError } from './utils/alertError';

export const CheckForceReload = () => {
  const { data } = useUserDataStore();

  useEffect(() => {}, []);

  const reload = async (docRef: DocumentReference<DocumentData>) => {
    const auth = getAuth();
    if (!auth.currentUser) return;
    const data = (await getDoc(docRef)).data();
    if (!data?.shouldReload) return;
    await setDoc(docRef, { shouldReload: false }, { merge: true });
    await auth.currentUser.getIdToken(true);
    await auth.currentUser.reload();
    window.location.reload();
  };

  useEffect(() => {
    if (!data) return;
    const docRef = doc(db, 'usersEditable', data.authUser.uid);

    const unsubscribe = onSnapshot(
      docRef,
      () => {
        reload(docRef)
          .then(() => {})
          .catch(() => {});
      },
      (err: unknown) => {
        alertError(err);
      },
    );

    return () => unsubscribe();
  }, [data]);

  return null;
};
