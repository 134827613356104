import posthog from 'posthog-js';
import { useSearchStore } from '../searchStore';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { initSearchWebSocket } from '@/services/api/actions/search';
import { SearchResult } from '@/types/api';
import devlog from '@/utils/devlog';
import { catchAppError } from '@/utils/createAppError';
import { alertError } from '@/utils/alertError';
import { POSTHOG_EVENT } from '@/services/posthog/events';

const onSearchId = (searchId: string, ws: WebSocket) => {
  devlog('WS SEARCH ID', searchId);
  useSearchStore.setState(
    (state) => {
      if (state.searches[searchId]) {
        throw new Error('search id already exists');
      }
      state.searches[searchId] = {
        ws,
        results: null,
        submittedParams: state.currentParams,
      };
      state.error = null;
      state.searchIdHistory = [searchId, ...state.searchIdHistory];
    },
    false,
    'searches/ws-connected',
  );
};

const onResult = (searchId: string, results: SearchResult) => {
  if (results.progress.complete) {
    posthog.capture(POSTHOG_EVENT.SEARCH_COMPLETE, { searchId });
  }

  useSearchStore.setState(
    (state) => {
      state.searches[searchId].results = results;
    },
    false,
    'searches/set-results',
  );
};

const onShutdown = (searchId: string | null) => {
  devlog('WS SHUTDOWN', searchId);
  if (!searchId) return;
  useSearchStore.setState(
    (state) => {
      state.searches[searchId].ws = null;
    },
    false,
    'searches/search-shutdown',
  );
};

const onError = (err: AppError) => {
  devlog('WS ERROR', err);
  setSearchError(err);
};

const setCreatingSearch = (isCreatingSearch: boolean) => {
  useSearchStore.setState(
    (state) => {
      state.isCreatingSearch = isCreatingSearch;
    },
    false,
    'searches/set-creating-search',
  );
};

const setSearchError = (error: AppError | null) => {
  useSearchStore.setState(
    (state) => {
      state.error = error;
    },
    false,
    'searches/set-error',
  );
};

const sanitizeSearchHistory = () => {
  useSearchStore.setState(
    (state) => {
      for (const searchId of state.searchIdHistory) {
        const ws = state.searches[searchId]?.ws;
        if (ws) {
          ws.close();
        } else {
          state.searches[searchId].ws = null;
        }
      }

      state.searchIdHistory = state.searchIdHistory.slice(0, MAX_SEARCH_HISTORY);
      for (const searchId of Object.keys(state.searches)) {
        if (!state.searchIdHistory.includes(searchId)) {
          delete state.searches[searchId];
        }
      }
    },
    false,
    'searches/sanitize-search-history',
  );
};

const MAX_SEARCH_HISTORY = 5;

export const createNewSearch = async () => {
  try {
    sanitizeSearchHistory();

    setCreatingSearch(true);
    const { searchId, ws } = await initSearchWebSocket({
      onSearchId,
      onResult,
      onError,
      onShutdown,
    });
    setCreatingSearch(false);
    return { searchId, ws };
  } catch (err) {
    setCreatingSearch(false);
    alertError(err, { function: 'initSearchWebSocket' });
    setSearchError(catchAppError(err));
    throw err;
  }
};
