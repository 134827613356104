import React from 'react';
import ErrorMessage, { AppError } from '@/common/ErrorMessage/ErrorMessage';

type Props = {
  error: AppError | null;
};

export const AuthError = ({ error }: React.PropsWithChildren<Props>) => {
  return (
    <div className="">
      {error !== null && error.type !== 'unimportant' && error.type !== 'email-not-confirmed' && (
        <>
          <ErrorMessage className="text-xs font-medium text-qura-red" error={error} />
        </>
      )}
    </div>
  );
};
