import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { z } from 'zod';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';

const dataRoleSchema = z
  .object({
    role_name: z.string(),
    label: z.string(),
    example_prompts: z.array(z.string()),
    weight: z.number(),
    language: z.enum(['en', 'sv']),
  })
  .transform(convertSnakeToCamelCaseObject);

export const storedUserSchema = z.object({
  username: z.string(),
  companyId: z.string(),
  language: z.enum(['en', 'sv']),
  code: z.string().nullable().default(null),
  emailVerified: z.boolean(),
  dataRoles: z.array(dataRoleSchema),
  searchHistoryActive: z.boolean().default(true),
  shouldReload: z.boolean().default(false),
  hasSeenQuickGuide: z.boolean().default(false),
});

export type DataRole = z.infer<typeof dataRoleSchema>;
export type StoredUser = z.infer<typeof storedUserSchema>;

export async function getStoredUserRequest(uid: string) {
  const userDocRef = doc(db, 'users', uid);
  const publicUserDocRef = doc(db, 'usersEditable', uid);
  const userDocSnapshot = await getDoc(userDocRef);
  const publicUserDocSnapshot = await getDoc(publicUserDocRef);

  if (!userDocSnapshot.exists()) {
    throw new Error('User not found');
  }

  if (!publicUserDocSnapshot.exists()) {
    throw new Error('Public user not found');
  }

  const userDocData = userDocSnapshot.data();
  const publicUserDocData = publicUserDocSnapshot.data();

  const populatedData = {
    ...userDocData,
    ...publicUserDocData,
    dataRoles: (
      await Promise.all(
        userDocData.dataRoles.map(async (ref: DocumentReference) => {
          const docSnapshot = await getDoc(ref);
          return docSnapshot.data();
        }),
      )
    ).filter((data: unknown) => data !== undefined),
  };

  const storedUser = storedUserSchema.parse(populatedData);
  return storedUser;
}
