type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

export type ConvertSnakeToCamelCase<T> = {
  [K in keyof T as SnakeToCamelCase<K & string>]: T[K];
};

const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export function convertSnakeToCamelCaseObject<T>(obj: T): ConvertSnakeToCamelCase<T> {
  const result: any = {};
  if (typeof obj !== 'object' || obj === null) return obj as any;
  Object.entries(obj as { [s: string]: unknown }).forEach(([key, value]) => {
    let updatedValue = value;

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      updatedValue = convertSnakeToCamelCaseObject(value as T);
    }

    if (typeof value === 'object' && Array.isArray(value)) {
      updatedValue = value.map((item) => convertSnakeToCamelCaseObject(item));
    }

    const camelKey = snakeToCamel(key);
    result[camelKey] = updatedValue;
  });
  return result;
}

const camelToSnake = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function convertBackToSnakeCaseRecursive(obj: unknown): unknown {
  if (Array.isArray(obj)) return obj.map(convertBackToSnakeCaseRecursive);
  if (typeof obj !== 'object' || obj === null) return obj;

  const result: Record<string, unknown> = {};
  Object.entries(obj as { [s: string]: unknown }).forEach(([key, value]) => {
    const snakeKey = camelToSnake(key);
    result[snakeKey] = convertBackToSnakeCaseRecursive(value);
  });
  return result;
}
