import { useEffect, useState } from 'react';

export const useLocalStorage = <T = string>(storageKey: string, fallbackState: T) => {
  const [value, setValue] = useState<T>(
    JSON.parse(localStorage.getItem(storageKey) as string) ?? fallbackState,
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue] as const;
};
