import { AxiosError } from 'axios';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';

export const appErrorTypes = [
  'network',
  'unknown',
  'invalid-credentials',
  'unimportant',
  'identical-request',
  'invalid-signup-token',
  'message',
  'email-already-used',
  'email-not-confirmed',
  'no-results',
  'load-resources-failed',
  'email-verify-failed',
  'email-verify-success',
  'websocket-error',
  'ws-validation-error',
  'unauthenticated',
] as const;

export type AppErrorType = (typeof appErrorTypes)[number];

export const createAppError = (type: AppErrorType, message: string, details?: any) => {
  const appError: AppError = { type, message };

  if (details) {
    appError.details = details;
  }

  return appError;
};

export type CatchAppErrorOptions = {
  ignoreOnLine: boolean;
};

export const catchAppError = (error: unknown, options?: CatchAppErrorOptions) => {
  let type: AppErrorType = 'unknown';
  let message: string = 'Unknown error';
  let details: any = undefined;

  if (typeof error === 'string') {
    message = error;
  }

  if (error instanceof AxiosError) {
    message = '';

    if (error.config) {
      if (error.config.method) {
        message = error.config.method.toUpperCase() + ' ';
      }
      message += error.config.url;
    } else {
      message = 'Axios request';
    }

    message += ' failed';

    if (error.response) {
      message += ' with ' + error.response.status;
      if (error.response.data) {
        message += ` "${JSON.stringify(error.response.data)}"`;
      }
    }
  } else if (error instanceof Error) {
    message = error.stack ?? error.message;
  } else if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    message = error.message;
  } else {
    message = JSON.stringify(error);
  }

  if (
    typeof error === 'object' &&
    error !== null &&
    'type' in error &&
    typeof error.type === 'string' &&
    appErrorTypes.includes(error.type as AppErrorType)
  ) {
    type = error.type as AppErrorType;
  }

  if (typeof error === 'object' && error !== null && 'details' in error) {
    details = error.details;
  } else {
    details = JSON.parse(JSON.stringify(error));
  }

  if (type === 'unknown' && !options?.ignoreOnLine && !navigator.onLine) {
    type = 'network';
  }

  return createAppError(type, message, details);
};
