import { getScoreHue, getScoreLightness, getScoreSaturation } from '@/utils/getScoreHue';

type MatchScoreProps = {
  score: number | null;
  className?: string;
};

export const MatchScore = ({ score, className }: MatchScoreProps) => {
  return (
    <p
      className={`${className ?? ''} bg-qura-neutral-ghost`}
      style={{
        backgroundColor:
          score !== null
            ? `hsl(${getScoreHue(score)}, ${getScoreSaturation() * 100}%, ${getScoreLightness(score) * 100}%)`
            : undefined,
      }}>
      {score === null ? '-' : Math.floor(score * 100) + '%'}
    </p>
  );
};
